@if (!overview) {
    <div class="related-categories">
        <h4>Related categories</h4>
        <div class="categories">
            @for (category of categoriesToShow; track category) {
                <a class="category"
                   routerLink="/{{AppRoutes.Categories}}/{{category.id}}"
                   [queryParams]="{ sort: 'published', hits: 'descending' }">
                    <span>{{ category.name }}</span>
                </a>
            }
        </div>
        @if (categories.length > 8) {
            <button class="btn show-more" (click)="showMoreOrLess()">
                {{ showMoreBtn ? "Show more" : "Show less" }}
            </button>
        }
    </div>
}

@if (overview) {
    <div class="related-categories">
        <div class="categories">
            @for (category of categoriesToShow; track category) {
                <div class="category overview">
                    <span>{{ category.name }}</span>
                </div>
            }
        </div>
        @if (categories.length > 8) {
            <button class="btn show-more" (click)="showMoreOrLess()">
                {{ showMoreBtn ? "Show more" : "Show less" }}
            </button>
        }
    </div>
}
